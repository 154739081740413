import PropTypes from 'prop-types';
import { useMemo } from 'react';

import CarouselBox from 'components/CarouselBox';

import { isFirstItem } from 'lib/array';

import AdCarouselItem from './AdCarouselItem';

const AdsCarousel = ({ ads = [], className, gaEventCategory }) => {
  const adsSortedByPosition = useMemo(
    () => [...ads].sort((adA, adB) => adA.position - adB.position),
    [ads]
  );

  return (
    <CarouselBox
      arrowsProps={{
        offsetX: { left: '-left-2', right: '-right-2' },
      }}
      carouselProps={{ className: 'w-full' }}
      className={className}
      itemProps={{
        slidesPerView: 1,
        itemSize: 88,
        breakpoints: {
          smd: { itemSize: 80, slidesPerView: 1 },
          md: { itemSize: 95, slidesPerView: 1 },
        },
      }}
      list={adsSortedByPosition}
      render={(
        { id, url, image, mobileImage, tabletImage, description },
        index
      ) => (
        <AdCarouselItem
          key={url}
          description={description}
          desktopImage={image}
          gaEventCategory={gaEventCategory}
          id={id}
          mobileImage={mobileImage}
          priority={isFirstItem(index)}
          tabletImage={tabletImage}
          url={url}
        />
      )}
      autoPlay
      full
      showArrows
    />
  );
};

AdsCarousel.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  gaEventCategory: PropTypes.string.isRequired,
};

export default AdsCarousel;

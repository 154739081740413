import PropTypes from 'prop-types';

import Image from 'components/Image';

import { sendEvent } from 'lib/gtag';

import APP from 'constants/app';

const AdCarouselItem = ({
  id,
  description,
  desktopImage,
  gaEventCategory,
  mobileImage,
  priority,
  tabletImage,
  url,
}) => (
  <a
    href={url}
    onClick={() => {
      sendEvent({
        category: gaEventCategory,
        action: `small_ad_click__${id}`,
      });
    }}
  >
    <Image
      alt={description}
      className="rounded-3 [@media(min-width:480px)]:hidden"
      height={280}
      priority={priority}
      sizes="(max-width: 768px) 280px, 1px"
      src={mobileImage}
      srcSet={`
        ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
        ${mobileImage} 280w,
      `}
      width={280}
    />
    <Image
      alt={description}
      className="hidden w-full rounded-3 xl:!hidden [@media(min-width:480px)]:block"
      height={280}
      priority={priority}
      sizes="(max-width: 1280px) 576px, 1px"
      src={tabletImage}
      srcSet={`
        ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
        ${tabletImage} 576w,
      `}
      width={576}
    />
    <Image
      alt={description}
      className="hidden w-full rounded-3 xl:block"
      height={280}
      priority={priority}
      sizes="(min-width: 1280px) 1152px, 1px"
      src={desktopImage}
      srcSet={`
        ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
        ${desktopImage} 1152w,
      `}
      width={1152}
    />
  </a>
);

AdCarouselItem.propTypes = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  desktopImage: PropTypes.string.isRequired,
  gaEventCategory: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  priority: PropTypes.bool.isRequired,
  tabletImage: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default AdCarouselItem;
